<ui-modal [modalTitle]="(type === 'task' ? 'Notes' : 'Notes and Documents')" [footer]="false">
	<ng-container *ngIf="activityLog">
		<div class="d-flex mt-n4 mb-4">
			<div *ngFor="let t of tabs" class="px-3 py-4 flex-weight-1 selectable hoverable border-bottom d-flex align-items-center" [ngClass]="{ 'border-primary border-4': selectedTab === t.id, 'border-lightgrey': selectedTab !== t.id }" (click)="selectedTab = t.id">
				<span class="text-uppercase text-small text-bold mr-2" [ngClass]="{ 'text-secondary': selectedTab !== t.id }">{{t.description}}</span>
				<span *ngIf="t.count" class="badge" [ngClass]="{ 'badge-primary': selectedTab === t.id, 'badge-secondary': selectedTab !== t.id }">{{t.count}}</span>
			</div>
		</div>

		<ng-container *ngIf="selectedTab === 'notes'">
			<div class="flex-weight-1 mb-3">
				<form (submit)="newActivity()">
					<div class="input-group">
						<textarea rows="3" class="form-control" [(ngModel)]="note" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" placeholder="Add note" appDesktopFocus></textarea>
						<span class="input-group-append">
							<button type="submit" class="btn btn-primary px-2"><i class="wq wq-chevron-right"></i></button>
						</span>
					</div>
				</form>
			</div>

			<div class="scrollable mx-n4 mb-n4 p-4" style="max-height: 600px;">
				<p *ngIf="!activityLog.length" class="text-center text-secondary">No notes to display.</p>
				<ng-container *ngFor="let activity of activityLog; index as i;">
					<div class="d-flex align-items-center mb-1">
						<span *ngIf="type === 'quote'" class="badge badge-secondary mr-2" style="overflow: hidden;">R{{activity.revision}}{{activity.change_order ? '.' + activity.change_order : ''}}</span>
						<div class="flex-weight-1">
							<b *ngIf="activity.created_user_id">{{activity.name}}</b>
							<b *ngIf="activity.created_customer_id" class="text-info">{{activity.customer_name}}</b>
							<span class="text-tiny text-secondary ml-2">{{activity.created_datetime | mySQLDateToISO | localizedDate:'shortDate'}} {{activity.created_datetime | mySQLDateToISO | localizedDate:'mediumTime'}}</span>
						</div>
						<button *ngIf="activity.my_note" class="btn btn-outline-secondary float-right border-0 p-1 mr-1" (click)="confirmDelete(i)"><i class="wq wq-delete"></i></button>
						<button *ngIf="activity.my_note" class="btn btn-outline-secondary float-right border-0 p-1" (click)="editMode(i)"><i class="wq wq-edit"></i></button>
					</div>
					<div *ngIf="activity.subtask_name" class="text-lines lines-1 mb-1 mt-n1 text-small" uiEllipsisTooltip><b class="text-info">Subtask:</b> {{activity.subtask_name}}</div>

					<div class="card shadow border-1 border-lightergrey p-2 mb-3" [ngClass]="{ 'bg-info-10': activity.created_customer_id, 'bg-success-10': activity.activity_type === 'quote_review_approve', 'bg-danger-10': activity.activity_type === 'quote_review_reject' }">
						<ng-container *ngIf="activity.activity_type === 'note'">
							<div *ngIf="editing !== i" class="text-pre" [innerHTML]="activity.note | linky"></div>
							<div *ngIf="editing === i">
								<form (submit)="save()">
									<div class="input-group">
										<textarea rows="3" class="form-control" [(ngModel)]="editNote" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off"></textarea>
										<span class="input-group-append">
											<button type="submit" class="btn btn-success px-2"><i class="wq wq-check"></i></button>
											<button type="button" class="btn btn-secondary px-2" (click)="editing = null"><i class="wq wq-close"></i></button>
										</span>
									</div>
								</form>
							</div>
						</ng-container>
						<ng-container *ngIf="activity.activity_type === 'quote_customer_accept'">
							<div class="text-bold">Customer has accepted this quote:</div>
							<div *ngIf="activity.json_data?.signature" class="mt-3"><img [src]="activity.json_data.signature | safeUrl" style="width: 100%;"></div>
							<div *ngIf="activity.json_data?.name" class="mt-3">{{activity.json_data.name}}</div>
						</ng-container>
						<ng-container *ngIf="activity.activity_type === 'quote_review_approve'">
							<div class="text-bold mb-3">Quote has passed review:</div>
							<div class="text-pre" [innerHTML]="activity.note | linky"></div>
						</ng-container>
						<ng-container *ngIf="activity.activity_type === 'quote_review_reject'">
							<div class="text-bold mb-3">Quote was rejected on review:</div>
							<div class="text-pre" [innerHTML]="activity.note | linky"></div>
						</ng-container>
						<ng-container *ngIf="activity.activity_type === 'quote_customer_view'">
							<ng-container *ngIf="activity.viewing_now">
								<div class="text-bold mb-3">Customer is viewing the proposal now.</div>
								<div class="text-bold">Viewing the proposal for {{activity.minutes - 4 | decimal:0:0:true}} {{activity.minutes - 4 === 1 ? 'minute' : 'minutes'}}.</div>
							</ng-container>

							<ng-container *ngIf="!activity.viewing_now">
								<div class="text-bold">Customer viewed the proposal for {{activity.minutes - 4 | decimal:0:0:true}} {{activity.minutes - 4 === 1 ? 'minute' : 'minutes'}}.</div>
							</ng-container>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</ng-container>

		<ng-container *ngIf="selectedTab === 'documents'">
			<div class="flex-weight-1 mb-3">
				<form (submit)="newDocument()">
					<div class="input-group">
						<div class="flex-weight-1 control-container">
							<input class="form-control flat-right flat-bottom" [(ngModel)]="docName" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" placeholder="New document name" appDesktopFocus>
							<input class="form-control flat-right flat-top" [(ngModel)]="docUrl" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" placeholder="New document URL">
						</div>
						<span class="input-group-append">
							<button type="submit" class="btn btn-primary px-2"><i class="wq wq-chevron-right"></i></button>
						</span>
					</div>
				</form>
			</div>

			<div class="scrollable mx-n4 mb-n4 p-4" style="max-height: 600px;">
				<p *ngIf="!documents.length" class="text-center text-secondary">No documents to display.</p>

				<table *ngIf="documents.length" class="table table-hover vam">
					<thead>
						<tr>
							<th>Document</th>
							<th>Added By</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let activity of documents">
							<td>
								<a [href]="activity.json_data.url" target="_blank">{{activity.note}}</a>
							</td>
							<td>
								<div class="d-flex align-items-center mb-1">
									<span class="badge badge-secondary mr-2" style="overflow: hidden;">R{{activity.revision}}{{activity.change_order ? '.' + activity.change_order : ''}}</span>
									<div class="flex-weight-1">
										<b *ngIf="activity.created_user_id">{{activity.name}}</b>
									</div>
								</div>
								<span class="text-tiny text-secondary">{{activity.created_datetime | mySQLDateToISO | localizedDate:'shortDate'}} {{activity.created_datetime | mySQLDateToISO | localizedDate:'mediumTime'}}</span>
							</td>
							<td class="shrink nowrap">
								<button *ngIf="activity.my_note" class="btn btn-outline-secondary float-right border-0 p-1 mr-1" (click)="deleteDocument(activity)"><i class="wq wq-delete"></i></button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</ng-container>
	</ng-container>
</ui-modal>
