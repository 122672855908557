<form>
	<ui-modal [modalTitle]="id === 'new' ? 'Add Project' : 'Project Details'">
		<ng-container *ngIf="data">
			<ui-form labelWidth="10rem">
				<ui-form-control label="Customer">
					<div class="d-flex">
						<input class="form-control" [(ngModel)]="data.customer_name" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" readonly>
						<ng-container *ngIf="!customerId && !data.locked">
							<button type="button" class="btn btn-outline-secondary p-2 ml-1" (click)="selectCustomerModal.open()"><i class="wq wq-search"></i></button>
							<div *ngIf="!app.orgInfo?.disallow_contact_edit" class="dropdown dropdown-light d-inline-block ml-1">
								<button type="button" class="btn btn-outline-success p-2 dropdown-toggle" data-toggle="dropdown"><i class="wq wq-add"></i></button>
								<div class="dropdown-menu">
									<button type="button" class="dropdown-item dropdown-item-success" (click)="addCustomer()">Add Customer</button>
									<a [href]="'/' + app.org + '/customer'" target="_blank" class="dropdown-item dropdown-item-success">Go to Customers<i class="wq wq-open-new ml-2"></i></a>
								</div>
							</div>
						</ng-container>
					</div>
				</ui-form-control>

				<ui-form-control label="Description">
					<input class="form-control" [(ngModel)]="data.project.description" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" appAutoFocus>
				</ui-form-control>
				<ui-form-control *ngIf="id !== 'new'" label="Project #">
					<input class="form-control text-right w-30" [(ngModel)]="data.project.project_no" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<hr>
				<ui-form-control label="Assignee">
					<select class="custom-select" [(ngModel)]="data.project.user_id" [ngModelOptions]="{ standalone:true }">
						<option [ngValue]="null"></option>
						<option *ngFor="let u of userList" [ngValue]="u.id">{{u.name}}</option>
					</select>
				</ui-form-control>
				<ui-form-control *ngIf="id === 'new' && data.currencies.length > 1" label="Currency">
					<select class="custom-select w-auto" [(ngModel)]="data.project.currency_id" [ngModelOptions]="{ standalone: true }" [disabled]="currencyId">
						<option *ngFor="let c of data.currencies" [ngValue]="c.currency_id">{{c.currency_id}}</option>
					</select>
				</ui-form-control>

				<ui-form-control label="Label" *ngIf="data?.label_list && data.label_list.length">
					<div #multiselectdropdown class="multi-select">
						<div class="selected-labels custom-select" (click)="toggleDropdown()">
							<span class="selectable mr-3" *ngFor="let labelData of selectedLabels">
								<ui-label class="selectable" [label_text]="labelData.text" [label_color]="labelData.label_color" (click)="toggleSelection(labelData);$event.stopPropagation();"></ui-label>
							</span>
						</div>
						<div class="dropdown">
							<div class="dropdown-menu shadow-sm bg-white border-lightgrey" [ngClass]="{ 'show': isDropdownOpen }">
								<ng-container *ngFor="let labelData of labelList">
									<div class="selectable hoverable rounded p-1" (click)="toggleSelection(labelData);$event.stopPropagation();">
										<ui-label [label_text]="labelData.text" [label_color]="labelData.label_color"></ui-label>
									</div>
								</ng-container>
								<div *ngIf="!labelList.length" class="text-secondary p-1">No more labels.</div>
							</div>
						</div>
					</div>
				</ui-form-control>

				<ng-container *ngIf="app.orgInfo?.allow_work_hub">
					<hr>
					<ui-form-control label="Schedule Colour">
						<div class="input-group">
							<div class="input-group-prepend">
								<p-colorPicker [(ngModel)]="data.project.schedule_color" [ngModelOptions]="{ standalone: true }"></p-colorPicker>
							</div>
							<input type="text" class="form-control" [(ngModel)]="data.project.schedule_color" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
						</div>
					</ui-form-control>
				</ng-container>

			</ui-form>
		</ng-container>

		<button *ngIf="id !== 'new' && data && !data.locked" type="button" class="btn btn-danger" (click)="deleteButton()" [disabled]="disabled" ui-modal-footer>Delete</button>
		<div class="flex-weight-1" ui-modal-footer></div>
		<button type="button" class="btn btn-secondary" (click)="modal.close()" [disabled]="disabled" ui-modal-footer>Cancel</button>
		<button type="submit" class="btn btn-primary" (click)="saveButton()" [disabled]="disabled" ui-modal-footer>Save</button>
	</ui-modal>
</form>

<modal-edit-customer (close)="customerEditModalClosed($event)"></modal-edit-customer>
<modal-select-customer (close)="selectCustomerModalClosed($event)"></modal-select-customer>
